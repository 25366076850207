var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-card',[_c('b-form-group',{attrs:{"label":"Usuário","label-for":"user"}},[_c('b-form-select',{attrs:{"id":"user","options":_vm.usersOptions},model:{value:(_vm.userSelected),callback:function ($$v) {_vm.userSelected=$$v},expression:"userSelected"}})],1)],1),_c('b-row',_vm._l((_vm.rates),function(item){return _c('b-col',{key:item.action_type_id,attrs:{"lg":"3","md":"4","sm":"6"}},[(item.action_type_id !== 14)?_c('b-card',{staticStyle:{"height":"250px"}},[_vm._v(" "+_vm._s(_vm.actionIdToActionType[item.action_type_id])+" "),_c('validation-provider',{attrs:{"name":"Valor da Porcentagem","rules":("required|" + (item.charge_type === 'percentage' ? 'percent-rate' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Taxa","label-for":("rate" + (item.action_type_id))}},[(item.charge_type === 'fixed')?_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:(item.charge_type === 'fixed' ? _vm.money : null),expression:"item.charge_type === 'fixed' ? money : null"}],attrs:{"id":("rate" + (item.action_type_id)),"state":errors.length > 0 ? false : null},model:{value:(item.rate),callback:function ($$v) {_vm.$set(item, "rate", $$v)},expression:"item.rate"}}):_c('b-form-input',{attrs:{"id":("rate" + (item.action_type_id)),"state":errors.length > 0 ? false : null},model:{value:(item.rate),callback:function ($$v) {_vm.$set(item, "rate", $$v)},expression:"item.rate"}}),_c('small',{staticClass:"text-danger text-left"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Valor da Porcentagem","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Tipo da Cobrança","label-for":("charge-" + (item.action_type_id))}},[_c('b-form-select',{attrs:{"id":("charge-" + (item.action_type_id)),"disabled":!item.is_new,"options":[
                { text: 'Porcentagem', value: 'percentage' },
                { text: 'Fixo', value: 'fixed' } ]},model:{value:(item.charge_type),callback:function ($$v) {_vm.$set(item, "charge_type", $$v)},expression:"item.charge_type"}}),_c('small',{staticClass:"text-danger text-left"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1):_vm._e()],1)}),1),((_vm.userSelected && _vm.actionsTypeOptions.length > 0))?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('rate-modal'),expression:"'rate-modal'"}],staticClass:"m-auto d-block",attrs:{"variant":"primary"}},[_vm._v(" Adicionar ")]):_vm._e(),(_vm.userSelected)?_c('b-button',{staticClass:"mx-auto d-block mt-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.updateRate()}}},[_vm._v(" Salvar ")]):_vm._e(),_c('b-modal',{attrs:{"id":"rate-modal","size":"md","title":"Adicionar Taxa","hide-footer":""}},[_c('b-form-group',{attrs:{"label":"Tipo"}},[_c('b-form-select',{attrs:{"options":_vm.actionsTypeOptions},model:{value:(_vm.newRateData.action_type_id),callback:function ($$v) {_vm.$set(_vm.newRateData, "action_type_id", $$v)},expression:"newRateData.action_type_id"}})],1),_c('b-form-group',{attrs:{"label":"Taxa"}},[(_vm.newRateData.charge_type === 'fixed')?_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],model:{value:(_vm.newRateData.rate),callback:function ($$v) {_vm.$set(_vm.newRateData, "rate", $$v)},expression:"newRateData.rate"}}):_c('b-form-input',{model:{value:(_vm.newRateData.rate),callback:function ($$v) {_vm.$set(_vm.newRateData, "rate", $$v)},expression:"newRateData.rate"}})],1),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Tipo da Cobrança"}},[_c('b-form-select',{attrs:{"disabled":"","options":[
          { text: 'Fixo', value: 'fixed' },
          { text: 'Porcentagem', value: 'percentage' } ]},on:{"change":function($event){return _vm.handleChangeChargeType()}},model:{value:(_vm.newRateData.charge_type),callback:function ($$v) {_vm.$set(_vm.newRateData, "charge_type", $$v)},expression:"newRateData.charge_type"}})],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.addRow()}}},[_vm._v(" Adicionar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }