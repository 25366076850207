<template>
  <b-container>
    <b-card>
      <b-form-group
        label="Usuário"
        label-for="user"
      >
        <b-form-select
          id="user"
          v-model="userSelected"
          :options="usersOptions"
        />
      </b-form-group>
    </b-card>

    <b-row>
      <b-col
        v-for="item in rates"

        :key="item.action_type_id"
        lg="3"
        md="4"
        sm="6"
      >
        <b-card
          v-if="item.action_type_id !== 14"
          style="height: 250px"
        >
          {{ actionIdToActionType[item.action_type_id] }}
          <validation-provider
            #default="{ errors }"
            name="Valor da Porcentagem"
            :rules="`required|${item.charge_type === 'percentage' ? 'percent-rate' : ''}`"
          >
            <b-form-group
              class="mt-1"
              label="Taxa"
              :label-for="`rate${item.action_type_id}`"
            >
              <b-form-input
                v-if="item.charge_type === 'fixed'"
                :id="`rate${item.action_type_id}`"
                v-model="item.rate"
                v-money="item.charge_type === 'fixed' ? money : null"
                :state="errors.length > 0 ? false : null"
              />

              <b-form-input
                v-else
                :id="`rate${item.action_type_id}`"
                v-model="item.rate"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger text-left">{{ errors[0] }}</small>
            </b-form-group>

          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Valor da Porcentagem"
            rules="required"
          >
            <b-form-group
              class="mt-1"
              label="Tipo da Cobrança"
              :label-for="`charge-${item.action_type_id}`"
            >
              <b-form-select
                :id="`charge-${item.action_type_id}`"
                v-model="item.charge_type"
                :disabled="!item.is_new"
                :options="[
                  { text: 'Porcentagem', value: 'percentage' },
                  { text: 'Fixo', value: 'fixed' },
                ]"
              />
              <small class="text-danger text-left">{{ errors[0] }}</small>
            </b-form-group>

          </validation-provider>
        </b-card>
      </b-col>
    </b-row>

    <b-button
      v-if="(userSelected && actionsTypeOptions.length > 0)"
      v-b-modal="'rate-modal'"
      variant="primary"
      class="m-auto d-block"
    >
      Adicionar
    </b-button>

    <b-button
      v-if="userSelected"
      variant="primary"
      class="mx-auto d-block mt-1"
      @click="updateRate()"
    >
      Salvar
    </b-button>

    <b-modal
      id="rate-modal"
      size="md"
      title="Adicionar Taxa"
      hide-footer
    >
      <b-form-group label="Tipo">

        <b-form-select
          v-model="newRateData.action_type_id"
          :options="actionsTypeOptions"
        />
      </b-form-group>
      <b-form-group label="Taxa">
        <b-form-input
          v-if="newRateData.charge_type === 'fixed'"
          v-model="newRateData.rate"
          v-money="money"
        />

        <b-form-input
          v-else
          v-model="newRateData.rate"
        />
      </b-form-group>

      <b-form-group
        class="mt-1"
        label="Tipo da Cobrança"
      >
        <b-form-select
          v-model="newRateData.charge_type"
          disabled
          :options="[
            { text: 'Fixo', value: 'fixed' },
            { text: 'Porcentagem', value: 'percentage' },
          ]"
          @change="handleChangeChargeType()"
        />
      </b-form-group>

      <b-button
        variant="primary"
        @click="addRow()"
      >
        Adicionar
      </b-button>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BButton,
  BCard,
  BContainer,
  BFormGroup,
  BFormSelect,
  BCol,
  BRow,
  BFormInput,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import GroupService from '@/service/credit_platform/groups'
import RatesService from '@/service/credit_platform/rates'

import '@validations'
import { callLoading } from '@/service/utilities'

const percentageRatesId = [15]

const actionIdToActionType = {
  1: 'CRIAÇÃO DE OPT-IN',
  2: 'EDIÇÃO DE OPT-IN',
  3: 'BUSCA DE OPT-IN',
  4: 'OPT-IN REJEITADO',
  5: 'OPT-OUT',
  6: 'MANUTENÇÃO DE AVERBAÇÃO',
  7: 'EDIÇÃO DE AVERBAÇÃO',
  8: 'BUSCA DE AVERBAÇÃO',
  9: 'CONSULTA DE AGENDA',
  10: 'CRIAR RASCUNHO DA AVERBAÇÃO',
  11: 'EDITAR RASCUNHO DA AVERBAÇÃO',
  12: 'APAGAR RASCUNHO DA AVERBAÇÃO',
  13: 'CONSULTA DE AGENDA',
  14: 'RELATIONSHIP_CHARGE',
  15: 'CRIAR AVERBAÇÃO',
}

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCard,
    BContainer,
    BFormGroup,
    BFormSelect,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      allUsers: [],
      allActionType: [],
      rates: [],
      userSelected: null,
      actionIdToActionType,
      isFetching: true,

      newRateData: {
        action_type_id: null,
        rate: 0,
        charge_type: 'fixed',
        is_new: true,
      },

      money: {
        decimal: '.',
        thousands: '',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
    }
  },

  computed: {
    usersOptions() {
      const allOsirisUsers = this.allUsers.map(item => ({
        value: item.id,
        text: item.name,
      }))

      return allOsirisUsers.sort((a, b) => a.text.localeCompare(b.text))
    },

    actionsTypeOptions() {
      if (!this.allActionType.length > 0) {
        return
      }

      // eslint-disable-next-line consistent-return
      return this.allActionType.filter(action => !this.rates.find(rate => action.id === rate.action_type_id)).map(action => ({
        value: action.id,
        text: actionIdToActionType[action.id],
      })).sort((a, b) => a.text.localeCompare(b.text))
    },

    chargeType() {
      return this.newRateData.action_type_id
    },
  },

  watch: {
    userSelected(id) {
      this.getGroup(id)
    },

    chargeType(value) {
      if (value === 15) {
        this.newRateData.rate = '0'
      }
      this.newRateData.charge_type = value === 15 ? 'percentage' : 'fixed'
    },

    isFetching(value) {
      callLoading(value)
    },
  },

  created() {
    this.getAllActionType()
    this.getAllGroups()
  },

  methods: {
    async getGroup(id) {
      this.isFetching = true
      const { data } = await RatesService.getByGroupId(id)
      this.rates = data.data.map(item => {
        const isPercentage = percentageRatesId.includes(item.action_type_id)

        return {
          ...item,
          rate: isPercentage ? item.rate : `R$ ${item.rate.toFixed(2)}`,
          charge_type: percentageRatesId.includes(item.action_type_id) ? 'percentage' : 'fixed',
        }
      }).sort((a, b) => actionIdToActionType[a.action_type.id].localeCompare(actionIdToActionType[b.action_type.id]))
      this.isFetching = false
    },

    async getAllActionType() {
      const data = [
        {
          id: 1,
          type: 'CREATE OPT IN',
          created_at: '2022-06-23T13:44:45.000-03:00',
          updated_at: '2022-06-23T13:44:45.000-03:00',
        },
        {
          id: 2,
          type: 'UPDATE OPT IN',
          created_at: '2022-06-23T13:44:45.000-03:00',
          updated_at: '2022-06-23T13:44:45.000-03:00',
        },
        {
          id: 3,
          type: 'SEARCH OPT IN',
          created_at: '2022-06-23T13:44:45.000-03:00',
          updated_at: '2022-06-23T13:44:45.000-03:00',
        },
        {
          id: 4,
          type: 'REJECT OPT IN',
          created_at: '2022-06-23T13:44:45.000-03:00',
          updated_at: '2022-06-23T13:44:45.000-03:00',
        },
        {
          id: 5,
          type: 'OPT OUT',
          created_at: '2022-06-23T13:44:45.000-03:00',
          updated_at: '2022-06-23T13:44:45.000-03:00',
        },
        {
          id: 6,
          type: 'CREATE ENDORSEMENT',
          created_at: '2022-06-23T13:44:46.000-03:00',
          updated_at: '2022-06-23T13:44:46.000-03:00',
        },
        {
          id: 7,
          type: 'UPDATE ENDORSEMENT',
          created_at: '2022-06-23T13:44:46.000-03:00',
          updated_at: '2022-06-23T13:44:46.000-03:00',
        },
        {
          id: 8,
          type: 'SEARCH ENDORSEMENT',
          created_at: '2022-06-23T13:44:46.000-03:00',
          updated_at: '2022-06-23T13:44:46.000-03:00',
        },
        {
          id: 9,
          type: 'NEW SEARCH SCHEDULE',
          created_at: '2022-06-23T13:44:46.000-03:00',
          updated_at: '2022-06-23T13:44:46.000-03:00',
        },
        {
          id: 10,
          type: 'CREATE_ENDORSEMENT_DRAFT',
          created_at: '2022-06-23T13:44:46.000-03:00',
          updated_at: '2022-06-23T13:44:46.000-03:00',
        },
        {
          id: 11,
          type: 'UPDATE_ENDORSEMENT_DRAFT',
          created_at: '2022-06-23T13:44:46.000-03:00',
          updated_at: '2022-06-23T13:44:46.000-03:00',
        },
        {
          id: 12,
          type: 'DELETE_ENDORSEMENT_DRAFT',
          created_at: '2022-06-23T13:44:46.000-03:00',
          updated_at: '2022-06-23T13:44:46.000-03:00',
        },
        {
          id: 13,
          type: 'SEARCH_SCHEDULE',
          created_at: '2022-06-23T13:44:46.000-03:00',
          updated_at: '2022-06-23T13:44:46.000-03:00',
        },
        {
          id: 14,
          type: 'RELATIONSHIP CHARGE',
          created_at: '2022-06-23T13:44:46.000-03:00',
          updated_at: '2022-06-23T13:44:46.000-03:00',
        },
        {
          id: 15,
          type: 'ENDORSEMENT CHARGE',
          created_at: '2022-06-23T13:44:46.000-03:00',
          updated_at: '2022-06-23T13:44:46.000-03:00',
        },
      ]

      this.allActionType = data
    },

    async getAllGroups() {
      const { data: { data } } = await GroupService.getAllGroups()
      this.allUsers = data
    },

    async confirmAnAction(message) {
      try {
        const response = await this.$bvModal
          .msgBoxConfirm(message, {
            cancelVariant: 'outline-secondary',
            centered: true,
            okTitle: 'Sim',
            cancelTitle: 'Não',
          })
        return response
      } catch {
        return false
      }
    },

    modalSuccess(text) {
      this.$swal({
        icon: 'success',
        text,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    modalError(text) {
      this.$swal({
        icon: 'error',
        text,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    },

    async updateRate() {
      const isConfirmed = await this.confirmAnAction('Deseja mesmo atualizar as taxas?')

      if (!isConfirmed) {
        return
      }
      const removeSymbolsMoney = value => (typeof value === 'string'
        ? +value.replace(/[R$]/g, '').replace(',', '.')
        : value)

      const rates = this.rates.map(item => ({
        ...item,
        rate: removeSymbolsMoney(item.rate),
      }))

      this.isFetching = true
      const { status, data } = await RatesService.updateByGroupId({ data: rates }, this.userSelected)
      this.rates = data.data.map(item => {
        const isPercentage = percentageRatesId.includes(item.action_type_id)

        return {
          ...item,
          rate: isPercentage ? item.rate : `R$ ${item.rate.toFixed(2)}`,
          charge_type: isPercentage ? 'percentage' : 'fixed',
        }
      }).sort((a, b) => actionIdToActionType[a.action_type_id].localeCompare(actionIdToActionType[b.action_type_id]))

      if (status === 200) {
        this.modalSuccess('Taxas atualizadas com sucesso')
      } else {
        this.modalError('Erro ao atualizar taxas')
      }
      this.isFetching = false
    },

    addRow() {
      this.rates.push(this.newRateData)
      this.newRateData = {
        action_type_id: null,
        rate: 0,
        charge_type: 'fixed',
      }
    },

    handleChangeChargeType(event) {

    },
  },
}
</script>
